import React from 'react';
import { BsTwitter, BsLinkedin } from 'react-icons/bs';
import { FaGithub } from 'react-icons/fa';

const SocialMedia = () => (
  <div className="app__social">
     <a href="https://www.linkedin.com/in/louis-you">
    <div>
      <BsLinkedin />
    </div>
    </a>
    <a href="https://github.com/youcodes">
    <div>
      <FaGithub />
    </div>
    </a>
    <a href="https://twitterxtesla.vercel.app">
    <div>
     <BsTwitter />
    </div>
    </a>
  </div>
);

export default SocialMedia;
