import React, { useState } from 'react';
import { images } from '../../constants';
import { AppWrap, MotionWrap } from '../../wrapper';
import { client } from '../../client';
import './Footer.scss';


const Footer = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const { username, email, message } = formData;

  const handleChangeInput = (e) => {
   const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  var emailValidated = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,}$/i.test(formData.email)
  const handleSubmit = () => {
   
    setLoading(true);
    
    const contact = {
      _type: 'contact',
      name: formData.username,
      email: formData.email,
      message: formData.message,
    };
 if(emailValidated){
    client.create(contact)
      .then(() => {
        setLoading(false);
        setIsFormSubmitted(true);
      })
      .catch((err) => console.log(err));
    }
    };


  return (
    <>
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.0-beta1/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-0evHe/X+R7YkIZDRvuzKMRqM+OrBnVFBL6DOitfPri4tjfHxaWutUpFmBp4vmVor" crossorigin="anonymous"></link>
        <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js" defer></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.3/umd/popper.min.js" defer></script>
    <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.1.0/js/bootstrap.min.js" defer integrity="sha384-uefMccjFJAIv6A+rW+L4AHf99KvxDjWSu1z9VI8SKNVmz4sk7buKt/6v9KI65qnm" crossorigin="anonymous"></script>
      <h2 className="head-text">Connect With Me</h2>

      <div className="app__footer-cards">
         <div className="app__footer-card ">
          <a href="https://www.linkedin.com/in/louis-you" > <img src="/linkedinlogo.png" alt="email" /></a>
          <a href="https://www.linkedin.com/in/louis-you" className="p-text">My Linkedin</a>
        </div>

        <div className="app__footer-card ">
           <a href="mailto:louis@louisyou.com"><img src={images.email} alt="email" /></a> 
          <a href="mailto:louis@louisyou.com" className="p-text">louis@louisyou.com</a>
        </div>
        
        <div className="app__footer-card ">
          <a href="https://github.com/youcodes">  <img src="/githublogo.png" alt="email" /></a>
          <a href="https://github.com/youcodes" className="p-text">My Github</a>
        </div>
         
         <div className="app__footer-card ">
          <a href="/resume.pdf">  <img src="/resume.png" alt="email" /></a>
          <a href="/resume.pdf" className="p-text">My Resume</a>
        </div>
      </div>

      {/* <form class="row g-3">
  <div class="col-md-4">
    <label for="validationDefault01" class="form-label">First name</label>
    <input type="text" class="form-control" id="validationDefault01" value="Mark" required />
  </div>
  <div class="col-md-4">
    <label for="validationDefault02" class="form-label">Last name</label>
    <input type="text" class="form-control" id="validationDefault02" value="Otto" required />
  </div>
  <div class="col-md-4">
    <label for="validationDefaultUsername" class="form-label">Username</label>
    <div class="input-group">
      <span class="input-group-text" id="inputGroupPrepend2">@</span>
      <input type="text" class="form-control" id="validationDefaultUsername"  aria-describedby="inputGroupPrepend2" required />
    </div>
  </div>
  <div class="col-md-6">
    <label for="validationDefault03" class="form-label">City</label>
    <input type="text" class="form-control" id="validationDefault03" required />
  </div>
  <div class="col-md-3">
    <label for="validationDefault04" class="form-label">State</label>
    <select class="form-select" id="validationDefault04" required>
      <option selected disabled value="">Choose...</option>
      <option>...</option>
    </select>
  </div>
  <div class="col-md-3">
    <label for="validationDefault05" class="form-label">Zip</label>
    <input type="text" class="form-control" id="validationDefault05" required />
  </div>
  <div class="col-12">
    <div class="form-check">
      <input class="form-check-input" type="checkbox" value="" id="invalidCheck2" required />
      <label class="form-check-label" for="invalidCheck2">
        Agree to terms and conditions
      </label>
    </div>
  </div>
  <div class="col-12">
    <button class="btn btn-primary" type="submit">Submit form</button>
  </div>
</form> */}

      {!isFormSubmitted ? (
        <div className="app__footer-form app__flex">
          <div className="app__flex">
            <input autocomplete="off" id="validationDefault02" className="p-text form-control" type="text" placeholder="Your Name" name="username" maxlength="100" value={username} onChange={handleChangeInput} required />
          </div>
        
          <div className="app__flex">
            <input autocomplete="off" className="p-text form-control"  id="emailAddress" type="email" placeholder="Your Email" name="email" maxlength="100" value={email} data-sb-validations="required, email" onChange={handleChangeInput} required/>
          </div>
          <div>
            <textarea
              className="p-text form-control"
              placeholder="Your Message"
              value={message}
              maxlength="3000"
              name="message"
              onChange={handleChangeInput}
            />
          </div>

          <button type="button" className="p-text" onClick={handleSubmit}>{emailValidated ? 'Send Message' : 'Valid Info Please'}</button>
        </div>
      ) : (
        <div>
          <h3 className="head-text">
            Thanks for getting in touch, <br /> I'll get back to you soon!
          </h3>

        </div>
        
      )}
    </>
  );
};

export default AppWrap(
  MotionWrap(Footer, 'app__footer'),
  'contact',
  'app__whitebg',
);
