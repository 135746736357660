import React from "react";
import { motion } from "framer-motion";
// eslint-disable-next-line
import { FaReact } from "react-icons/fa";

import { AppWrap } from "../../wrapper";
import { images } from "../../constants";
import "./Header.scss";

const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: "easeInOut",
    },
  },
};

const Header = () => (
  <div className="app__header app__flex">
    <motion.div
      whileInView={{ x: [-100, 0], opacity: [0, 1] }}
      transition={{ duration: 0.5 }}
      className="app__header-info"
    >
      <div className="unselectable app__header-badge">
        <div className="badge-cmp app__flex">
          {/* <span>👋</span> */}
          {/* marginLeft: 20 if you want emoji */}
          <div style={{ marginLeft: 0 }}>
            <p className="p-text">Hi, I'm</p>
            <h1 className="head-text">Louis</h1>
          </div>
        </div>

        <div className="tag-cmp app__flex">
          <p className="p-text">Research Associate</p>
          <p className="p-text">Web Developer</p>
        </div>
      </div>
    </motion.div>

    <motion.div
      whileInView={{ opacity: [0, 1] }}
      transition={{ duration: 0.5, delayChildren: 0.5 }}
      className="app__header-img unselectable"
    >
      {/* <img src={images.profile} alt="profile_bg" /> */}
      <motion.img
        whileInView={{ scale: [0, 1] }}
        transition={{ duration: 1, ease: "easeInOut" }}
        src={images.profile}
        alt="profile_circle"
        className="overlay_circle unselectable"
      />
    </motion.div>

    <motion.div
      variants={scaleVariants}
      whileInView={scaleVariants.whileInView}
      className="app__header-circles unselectable"
    >
      {[images.git, images.sass, images.electron].map((circle, index) => (
        <div
          className="circle-cmp app__flex unselectable"
          key={`circle-${index}`}
        >
          <img src={circle} alt="profile_bg" />
        </div>
      ))}
    </motion.div>
  </div>
);

export default AppWrap(Header, "home");
