import React from 'react';
import { BrowserRouter, Route, Switch} from "react-router-dom"
import { About, Footer, Header, Skills, Work } from './container';
import { Navbar } from './components';
import './App.scss';

const App = () => (
  <div className="app">
    <Navbar />
    <Header />
      <section>
        <div class="wave water"></div>
         <div class="wave water"></div>
          <div class="wave water"></div>
           <div class="wave water"></div>
      </section>
    <About />
    <Skills />
    <Work />
    <Footer />
  </div>
);

export default App;
